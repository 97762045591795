import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const client = createClient({
    projectId: 'zb76wug4',
    dataset: 'production', 
    apiVersion: '2023-09-11',
    useCdn: true,
    token: 'skJkofIC25Gu0n0c6Hdy8CMpqIpFr1gYBPvqK9SiN563I4pDDfFBiNVx8HvXCr4NhZMXCqirRCsIxddXtPSpOjeOwzB6eoL4waIusx5GBTsAdE62Qc5FmTMCCp4T0Ia5aAPGbKLDfCy17ymNLNwDMvjDNNL1cQu3nAGuhl2YUiEKsR4zsxS9',
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);