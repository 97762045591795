import React from 'react';
import { motion } from 'framer-motion';

import { AppWrap } from '../../wrapper';

import { images } from '../../constants';
import './Header.scss';

const scaleVariants = {
  whileInView: {
    scale: [0,1],
    opacity: [0,1],
    transition: {
      duration: 1,
      ease: 'easeInOut'
    }
  }
}
const Header = () => {
  return (
    <div className='app__header app__flex'>
      <motion.div
        whileInView={{ x: [-100, 0], opacity: [0, 1]}}
        transition={{ duration: 0.5}}
        className='app__header-info'
      >
        <div className='app__header-badge'>
          <div className='badge-cmp app__flex'>
            <div style={{ marginLeft: 20}}>
              <p className="p-text">Hi! My name is </p>
              <h1 className='head-text'>Sativa Maciel</h1>
            </div>
          </div>
          <div className='tag-cmp app__flex'>
            <p className='p-text'>As a Computer Science major specializing in Human-Computer Interaction and Mobile/Web Development my portfolio showcases a diverse range of projects that blend technical expertise with a deep understanding of user needs and interaction design principles.</p>
            <br />
            <p className="p-text">With a keen focus on creating intuitive and engaging digital experiences, I have developed skills in both front-end and back-end development, coupled with a strong foundation in HCI research and methodologies.</p>
          </div>
 
        </div>
      </motion.div>

      <motion.div
        whileInView={{ opacity: [0, 1]}}
        transition={{ duration: 0.5, delayChildren: 0.5}}
        className='app__header-img'
      >

      </motion.div>

    </div>
  )
}

export default AppWrap(Header, 'home');