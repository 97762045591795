import React from 'react';
import { BsInstagram, BsLinkedin } from 'react-icons/bs';

const SocialMedia = () => {
  return (
    <div className='app__social'>
        <div>
            <a href='https://www.linkedin.com/in/sativa-maciel/' target='_blank'>
              <BsLinkedin />
            </a>
        </div>
        <div>
            <a href='https://www.instagram.com/sativa_lemac/' target='_blank'>
              <BsInstagram />
            </a>
        </div>
    </div>
  )
}

export default SocialMedia